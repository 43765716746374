export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js?t=1742207030687"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js"),
	() => import("/.svelte-kit/generated/client/nodes/31.js"),
	() => import("/.svelte-kit/generated/client/nodes/32.js"),
	() => import("/.svelte-kit/generated/client/nodes/33.js"),
	() => import("/.svelte-kit/generated/client/nodes/34.js"),
	() => import("/.svelte-kit/generated/client/nodes/35.js"),
	() => import("/.svelte-kit/generated/client/nodes/36.js"),
	() => import("/.svelte-kit/generated/client/nodes/37.js"),
	() => import("/.svelte-kit/generated/client/nodes/38.js"),
	() => import("/.svelte-kit/generated/client/nodes/39.js"),
	() => import("/.svelte-kit/generated/client/nodes/40.js"),
	() => import("/.svelte-kit/generated/client/nodes/41.js"),
	() => import("/.svelte-kit/generated/client/nodes/42.js"),
	() => import("/.svelte-kit/generated/client/nodes/43.js"),
	() => import("/.svelte-kit/generated/client/nodes/44.js"),
	() => import("/.svelte-kit/generated/client/nodes/45.js"),
	() => import("/.svelte-kit/generated/client/nodes/46.js"),
	() => import("/.svelte-kit/generated/client/nodes/47.js"),
	() => import("/.svelte-kit/generated/client/nodes/48.js"),
	() => import("/.svelte-kit/generated/client/nodes/49.js"),
	() => import("/.svelte-kit/generated/client/nodes/50.js"),
	() => import("/.svelte-kit/generated/client/nodes/51.js"),
	() => import("/.svelte-kit/generated/client/nodes/52.js"),
	() => import("/.svelte-kit/generated/client/nodes/53.js?t=1742200844296"),
	() => import("/.svelte-kit/generated/client/nodes/54.js"),
	() => import("/.svelte-kit/generated/client/nodes/55.js"),
	() => import("/.svelte-kit/generated/client/nodes/56.js"),
	() => import("/.svelte-kit/generated/client/nodes/57.js"),
	() => import("/.svelte-kit/generated/client/nodes/58.js"),
	() => import("/.svelte-kit/generated/client/nodes/59.js"),
	() => import("/.svelte-kit/generated/client/nodes/60.js"),
	() => import("/.svelte-kit/generated/client/nodes/61.js"),
	() => import("/.svelte-kit/generated/client/nodes/62.js"),
	() => import("/.svelte-kit/generated/client/nodes/63.js"),
	() => import("/.svelte-kit/generated/client/nodes/64.js"),
	() => import("/.svelte-kit/generated/client/nodes/65.js"),
	() => import("/.svelte-kit/generated/client/nodes/66.js"),
	() => import("/.svelte-kit/generated/client/nodes/67.js"),
	() => import("/.svelte-kit/generated/client/nodes/68.js"),
	() => import("/.svelte-kit/generated/client/nodes/69.js"),
	() => import("/.svelte-kit/generated/client/nodes/70.js"),
	() => import("/.svelte-kit/generated/client/nodes/71.js"),
	() => import("/.svelte-kit/generated/client/nodes/72.js"),
	() => import("/.svelte-kit/generated/client/nodes/73.js"),
	() => import("/.svelte-kit/generated/client/nodes/74.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/75.js"),
	() => import("/.svelte-kit/generated/client/nodes/76.js"),
	() => import("/.svelte-kit/generated/client/nodes/77.js"),
	() => import("/.svelte-kit/generated/client/nodes/78.js"),
	() => import("/.svelte-kit/generated/client/nodes/79.js"),
	() => import("/.svelte-kit/generated/client/nodes/80.js"),
	() => import("/.svelte-kit/generated/client/nodes/81.js"),
	() => import("/.svelte-kit/generated/client/nodes/82.js"),
	() => import("/.svelte-kit/generated/client/nodes/83.js"),
	() => import("/.svelte-kit/generated/client/nodes/84.js"),
	() => import("/.svelte-kit/generated/client/nodes/85.js"),
	() => import("/.svelte-kit/generated/client/nodes/86.js"),
	() => import("/.svelte-kit/generated/client/nodes/87.js"),
	() => import("/.svelte-kit/generated/client/nodes/88.js"),
	() => import("/.svelte-kit/generated/client/nodes/89.js"),
	() => import("/.svelte-kit/generated/client/nodes/90.js"),
	() => import("/.svelte-kit/generated/client/nodes/91.js"),
	() => import("/.svelte-kit/generated/client/nodes/92.js"),
	() => import("/.svelte-kit/generated/client/nodes/93.js"),
	() => import("/.svelte-kit/generated/client/nodes/94.js"),
	() => import("/.svelte-kit/generated/client/nodes/95.js"),
	() => import("/.svelte-kit/generated/client/nodes/96.js"),
	() => import("/.svelte-kit/generated/client/nodes/97.js"),
	() => import("/.svelte-kit/generated/client/nodes/98.js"),
	() => import("/.svelte-kit/generated/client/nodes/99.js"),
	() => import("/.svelte-kit/generated/client/nodes/100.js"),
	() => import("/.svelte-kit/generated/client/nodes/101.js"),
	() => import("/.svelte-kit/generated/client/nodes/102.js"),
	() => import("/.svelte-kit/generated/client/nodes/103.js"),
	() => import("/.svelte-kit/generated/client/nodes/104.js"),
	() => import("/.svelte-kit/generated/client/nodes/105.js"),
	() => import("/.svelte-kit/generated/client/nodes/106.js"),
	() => import("/.svelte-kit/generated/client/nodes/107.js"),
	() => import("/.svelte-kit/generated/client/nodes/108.js"),
	() => import("/.svelte-kit/generated/client/nodes/109.js"),
	() => import("/.svelte-kit/generated/client/nodes/110.js"),
	() => import("/.svelte-kit/generated/client/nodes/111.js"),
	() => import("/.svelte-kit/generated/client/nodes/112.js"),
	() => import("/.svelte-kit/generated/client/nodes/113.js"),
	() => import("/.svelte-kit/generated/client/nodes/114.js"),
	() => import("/.svelte-kit/generated/client/nodes/115.js"),
	() => import("/.svelte-kit/generated/client/nodes/116.js"),
	() => import("/.svelte-kit/generated/client/nodes/117.js"),
	() => import("/.svelte-kit/generated/client/nodes/118.js"),
	() => import("/.svelte-kit/generated/client/nodes/119.js"),
	() => import("/.svelte-kit/generated/client/nodes/120.js"),
	() => import("/.svelte-kit/generated/client/nodes/121.js"),
	() => import("/.svelte-kit/generated/client/nodes/122.js"),
	() => import("/.svelte-kit/generated/client/nodes/123.js"),
	() => import("/.svelte-kit/generated/client/nodes/124.js"),
	() => import("/.svelte-kit/generated/client/nodes/125.js"),
	() => import("/.svelte-kit/generated/client/nodes/126.js"),
	() => import("/.svelte-kit/generated/client/nodes/127.js"),
	() => import("/.svelte-kit/generated/client/nodes/128.js"),
	() => import("/.svelte-kit/generated/client/nodes/129.js"),
	() => import("/.svelte-kit/generated/client/nodes/130.js"),
	() => import("/.svelte-kit/generated/client/nodes/131.js"),
	() => import("/.svelte-kit/generated/client/nodes/132.js"),
	() => import("/.svelte-kit/generated/client/nodes/133.js"),
	() => import("/.svelte-kit/generated/client/nodes/134.js"),
	() => import("/.svelte-kit/generated/client/nodes/135.js"),
	() => import("/.svelte-kit/generated/client/nodes/136.js"),
	() => import("/.svelte-kit/generated/client/nodes/137.js"),
	() => import("/.svelte-kit/generated/client/nodes/138.js"),
	() => import("/.svelte-kit/generated/client/nodes/139.js"),
	() => import("/.svelte-kit/generated/client/nodes/140.js"),
	() => import("/.svelte-kit/generated/client/nodes/141.js"),
	() => import("/.svelte-kit/generated/client/nodes/142.js"),
	() => import("/.svelte-kit/generated/client/nodes/143.js"),
	() => import("/.svelte-kit/generated/client/nodes/144.js"),
	() => import("/.svelte-kit/generated/client/nodes/145.js"),
	() => import("/.svelte-kit/generated/client/nodes/146.js"),
	() => import("/.svelte-kit/generated/client/nodes/147.js"),
	() => import("/.svelte-kit/generated/client/nodes/148.js"),
	() => import("/.svelte-kit/generated/client/nodes/149.js"),
	() => import("/.svelte-kit/generated/client/nodes/150.js"),
	() => import("/.svelte-kit/generated/client/nodes/151.js"),
	() => import("/.svelte-kit/generated/client/nodes/152.js"),
	() => import("/.svelte-kit/generated/client/nodes/153.js"),
	() => import("/.svelte-kit/generated/client/nodes/154.js"),
	() => import("/.svelte-kit/generated/client/nodes/155.js"),
	() => import("/.svelte-kit/generated/client/nodes/156.js"),
	() => import("/.svelte-kit/generated/client/nodes/157.js"),
	() => import("/.svelte-kit/generated/client/nodes/158.js"),
	() => import("/.svelte-kit/generated/client/nodes/159.js"),
	() => import("/.svelte-kit/generated/client/nodes/160.js"),
	() => import("/.svelte-kit/generated/client/nodes/161.js"),
	() => import("/.svelte-kit/generated/client/nodes/162.js"),
	() => import("/.svelte-kit/generated/client/nodes/163.js"),
	() => import("/.svelte-kit/generated/client/nodes/164.js"),
	() => import("/.svelte-kit/generated/client/nodes/165.js"),
	() => import("/.svelte-kit/generated/client/nodes/166.js"),
	() => import("/.svelte-kit/generated/client/nodes/167.js"),
	() => import("/.svelte-kit/generated/client/nodes/168.js"),
	() => import("/.svelte-kit/generated/client/nodes/169.js"),
	() => import("/.svelte-kit/generated/client/nodes/170.js"),
	() => import("/.svelte-kit/generated/client/nodes/171.js"),
	() => import("/.svelte-kit/generated/client/nodes/172.js"),
	() => import("/.svelte-kit/generated/client/nodes/173.js"),
	() => import("/.svelte-kit/generated/client/nodes/174.js"),
	() => import("/.svelte-kit/generated/client/nodes/175.js"),
	() => import("/.svelte-kit/generated/client/nodes/176.js"),
	() => import("/.svelte-kit/generated/client/nodes/177.js"),
	() => import("/.svelte-kit/generated/client/nodes/178.js"),
	() => import("/.svelte-kit/generated/client/nodes/179.js"),
	() => import("/.svelte-kit/generated/client/nodes/180.js"),
	() => import("/.svelte-kit/generated/client/nodes/181.js"),
	() => import("/.svelte-kit/generated/client/nodes/182.js"),
	() => import("/.svelte-kit/generated/client/nodes/183.js"),
	() => import("/.svelte-kit/generated/client/nodes/184.js"),
	() => import("/.svelte-kit/generated/client/nodes/185.js"),
	() => import("/.svelte-kit/generated/client/nodes/186.js"),
	() => import("/.svelte-kit/generated/client/nodes/187.js"),
	() => import("/.svelte-kit/generated/client/nodes/188.js"),
	() => import("/.svelte-kit/generated/client/nodes/189.js"),
	() => import("/.svelte-kit/generated/client/nodes/190.js"),
	() => import("/.svelte-kit/generated/client/nodes/191.js"),
	() => import("/.svelte-kit/generated/client/nodes/192.js"),
	() => import("/.svelte-kit/generated/client/nodes/193.js"),
	() => import("/.svelte-kit/generated/client/nodes/194.js"),
	() => import("/.svelte-kit/generated/client/nodes/195.js"),
	() => import("/.svelte-kit/generated/client/nodes/196.js"),
	() => import("/.svelte-kit/generated/client/nodes/197.js"),
	() => import("/.svelte-kit/generated/client/nodes/198.js"),
	() => import("/.svelte-kit/generated/client/nodes/199.js"),
	() => import("/.svelte-kit/generated/client/nodes/200.js"),
	() => import("/.svelte-kit/generated/client/nodes/201.js"),
	() => import("/.svelte-kit/generated/client/nodes/202.js"),
	() => import("/.svelte-kit/generated/client/nodes/203.js"),
	() => import("/.svelte-kit/generated/client/nodes/204.js"),
	() => import("/.svelte-kit/generated/client/nodes/205.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/206.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/207.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/208.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/209.js"),
	() => import("/.svelte-kit/generated/client/nodes/210.js"),
	() => import("/.svelte-kit/generated/client/nodes/211.js"),
	() => import("/.svelte-kit/generated/client/nodes/212.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/213.js"),
	() => import("/.svelte-kit/generated/client/nodes/214.js"),
	() => import("/.svelte-kit/generated/client/nodes/215.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/216.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/217.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/218.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/219.js"),
	() => import("/.svelte-kit/generated/client/nodes/220.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/221.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/222.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/223.js"),
	() => import("/.svelte-kit/generated/client/nodes/224.js"),
	() => import("/.svelte-kit/generated/client/nodes/225.js"),
	() => import("/.svelte-kit/generated/client/nodes/226.js"),
	() => import("/.svelte-kit/generated/client/nodes/227.js"),
	() => import("/.svelte-kit/generated/client/nodes/228.js"),
	() => import("/.svelte-kit/generated/client/nodes/229.js"),
	() => import("/.svelte-kit/generated/client/nodes/230.js"),
	() => import("/.svelte-kit/generated/client/nodes/231.js"),
	() => import("/.svelte-kit/generated/client/nodes/232.js"),
	() => import("/.svelte-kit/generated/client/nodes/233.js"),
	() => import("/.svelte-kit/generated/client/nodes/234.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/235.js"),
	() => import("/.svelte-kit/generated/client/nodes/236.js?t=1742197070461"),
	() => import("/.svelte-kit/generated/client/nodes/237.js"),
	() => import("/.svelte-kit/generated/client/nodes/238.js"),
	() => import("/.svelte-kit/generated/client/nodes/239.js"),
	() => import("/.svelte-kit/generated/client/nodes/240.js"),
	() => import("/.svelte-kit/generated/client/nodes/241.js"),
	() => import("/.svelte-kit/generated/client/nodes/242.js"),
	() => import("/.svelte-kit/generated/client/nodes/243.js"),
	() => import("/.svelte-kit/generated/client/nodes/244.js"),
	() => import("/.svelte-kit/generated/client/nodes/245.js"),
	() => import("/.svelte-kit/generated/client/nodes/246.js")
];

export const server_loads = [0,2,4,12,19,23,25,26,27,28,30,31,32,33,35,37,39,47,49,50,51];

export const dictionary = {
		"/": [~53],
		"/about": [54],
		"/advertise": [55],
		"/affiliate-program": [56],
		"/analysts": [~57],
		"/analysts/top-stocks": [~59],
		"/analysts/[slug]": [~58],
		"/auth": [~60],
		"/blog": [~61],
		"/blog/article/[slug]": [~62],
		"/changelog": [63],
		"/contact": [64],
		"/corporate-lobbying-tracker": [~65],
		"/dark-pool-flow": [~66],
		"/data-disclaimer": [67],
		"/dividends-calendar": [~68],
		"/donation": [69],
		"/earnings-calendar": [~70],
		"/economic-calendar": [~71],
		"/economic-indicator": [~72],
		"/etf": [~73],
		"/etf/etf-providers": [~92,[11]],
		"/etf/etf-providers/[slug]": [~93,[11]],
		"/etf/new-launches": [~94],
		"/etf/[tickerID]": [~74,[2]],
		"/etf/[tickerID]/dark-pool": [~75,[2,3]],
		"/etf/[tickerID]/dividends": [~76,[2,4]],
		"/etf/[tickerID]/history": [~77,[2]],
		"/etf/[tickerID]/holdings": [~78,[2]],
		"/etf/[tickerID]/insider": [~79,[2,5]],
		"/etf/[tickerID]/options": [~80,[2,6]],
		"/etf/[tickerID]/options/dex": [~81,[2,6,7]],
		"/etf/[tickerID]/options/dex/expiry": [~82,[2,6,7]],
		"/etf/[tickerID]/options/dex/strike": [~83,[2,6,7]],
		"/etf/[tickerID]/options/gex": [~84,[2,6,8]],
		"/etf/[tickerID]/options/gex/expiry": [~85,[2,6,8]],
		"/etf/[tickerID]/options/gex/strike": [~86,[2,6,8]],
		"/etf/[tickerID]/options/hottest-contracts": [~87,[2,6]],
		"/etf/[tickerID]/options/oi": [~88,[2,6,9]],
		"/etf/[tickerID]/options/oi/expiry": [~89,[2,6,9]],
		"/etf/[tickerID]/options/unusual-activity": [~90,[2,6]],
		"/etf/[tickerID]/options/volatility": [~91,[2,6,10]],
		"/heatmap": [95],
		"/hedge-funds": [~96],
		"/hedge-funds/[slug]": [~97],
		"/home": [98],
		"/imprint": [99],
		"/index/[tickerID]": [~100,[12]],
		"/index/[tickerID]/history": [~101,[12]],
		"/index/[tickerID]/holdings": [~102,[12]],
		"/index/[tickerID]/options": [~103,[12,13]],
		"/index/[tickerID]/options/dex": [~104,[12,13,14]],
		"/index/[tickerID]/options/dex/expiry": [~105,[12,13,14]],
		"/index/[tickerID]/options/dex/strike": [~106,[12,13,14]],
		"/index/[tickerID]/options/gex": [~107,[12,13,15]],
		"/index/[tickerID]/options/gex/expiry": [~108,[12,13,15]],
		"/index/[tickerID]/options/gex/strike": [~109,[12,13,15]],
		"/index/[tickerID]/options/hottest-contracts": [~110,[12,13]],
		"/index/[tickerID]/options/oi": [~111,[12,13,16]],
		"/index/[tickerID]/options/oi/expiry": [~112,[12,13,16]],
		"/index/[tickerID]/options/volatility": [~113,[12,13,17]],
		"/industry": [~114,[18]],
		"/industry/all": [~115,[18]],
		"/industry/sectors": [~116,[18]],
		"/insider-tracker": [~117],
		"/ipos": [118,[19]],
		"/ipos/news": [~120,[19]],
		"/ipos/[slug]": [~119,[19]],
		"/learning-center": [~121],
		"/learning-center/article/[slug]": [~122],
		"/list": [123,[20]],
		"/list/bitcoin-etfs": [~125,[20]],
		"/list/car-company-stocks": [~126,[20]],
		"/list/clean-energy": [~127,[20]],
		"/list/country/[slug]": [~128,[20]],
		"/list/dividend/[slug]": [~129,[20]],
		"/list/electric-vehicles": [~130,[20]],
		"/list/esports": [~131,[20]],
		"/list/exchange/[slug]": [~132,[20]],
		"/list/highest-income-tax": [~133,[20]],
		"/list/highest-open-interest-change": [~135,[20]],
		"/list/highest-open-interest": [~134,[20]],
		"/list/highest-option-iv-rank": [~136,[20]],
		"/list/highest-option-premium": [~137,[20]],
		"/list/highest-revenue": [~138,[20]],
		"/list/index/[slug]": [~139,[20]],
		"/list/industry/[slug]": [~140,[20]],
		"/list/market-cap/[slug]": [~141,[20]],
		"/list/mobile-games": [~142,[20]],
		"/list/most-buybacks": [~143,[20]],
		"/list/most-employees": [~144,[20]],
		"/list/most-ftd-shares": [~145,[20]],
		"/list/most-shorted-stocks": [~146,[20]],
		"/list/overbought-stocks": [~147,[20]],
		"/list/oversold-stocks": [~148,[20]],
		"/list/penny-stocks": [~149,[20]],
		"/list/reit-stocks": [~150,[20]],
		"/list/sector/[slug]": [~151,[20]],
		"/list/social-media-stocks": [~152,[20]],
		"/list/top-rated-dividend-stocks": [~153,[20]],
		"/list/[slug]": [~124,[20]],
		"/login": [~154],
		"/markdown-guide": [155],
		"/market-flow": [~156,[21]],
		"/market-flow/sector-flow": [~157,[21]],
		"/market-mover": [~158,[22]],
		"/market-mover/active": [159,[22,23]],
		"/market-mover/active/3Y": [160,[22,23]],
		"/market-mover/active/5Y": [161,[22,23]],
		"/market-mover/active/month": [162,[22,23]],
		"/market-mover/active/week": [163,[22,23]],
		"/market-mover/active/year": [164,[22,23]],
		"/market-mover/afterhours": [165,[22,24]],
		"/market-mover/afterhours/gainers": [166,[22,24,25]],
		"/market-mover/afterhours/losers": [167,[22,24,26]],
		"/market-mover/gainers": [168,[22,27]],
		"/market-mover/gainers/3Y": [169,[22,27]],
		"/market-mover/gainers/5Y": [170,[22,27]],
		"/market-mover/gainers/month": [171,[22,27]],
		"/market-mover/gainers/week": [172,[22,27]],
		"/market-mover/gainers/year": [173,[22,27]],
		"/market-mover/losers": [174,[22,28]],
		"/market-mover/losers/3Y": [175,[22,28]],
		"/market-mover/losers/5Y": [176,[22,28]],
		"/market-mover/losers/month": [177,[22,28]],
		"/market-mover/losers/week": [178,[22,28]],
		"/market-mover/losers/year": [179,[22,28]],
		"/market-mover/premarket": [180,[22,29]],
		"/market-mover/premarket/gainers": [181,[22,29,30]],
		"/market-mover/premarket/losers": [182,[22,29,31]],
		"/market-news": [183,[32]],
		"/market-news/general": [184,[32]],
		"/market-news/press-releases": [185,[32]],
		"/newsletter": [186],
		"/notifications": [~187],
		"/options-flow": [~188],
		"/politicians": [~189],
		"/politicians/flow-data": [~191],
		"/politicians/[slug]": [~190],
		"/potus-tracker": [~192],
		"/price-alert": [~193],
		"/pricing": [~194],
		"/privacy-policy": [195],
		"/profile": [~196],
		"/reddit-tracker": [~197],
		"/register": [~198],
		"/reset-password": [~199],
		"/sentiment-tracker": [~200],
		"/sitemaps": [~202],
		"/sitemap": [~201],
		"/stock-screener": [~203],
		"/stocks": [~204],
		"/stocks/[tickerID]": [~205,[33]],
		"/stocks/[tickerID]/dark-pool": [~206,[33,34]],
		"/stocks/[tickerID]/dividends": [~207,[33,35]],
		"/stocks/[tickerID]/financials": [~208,[33,36]],
		"/stocks/[tickerID]/financials/balance-sheet": [~209,[33,36]],
		"/stocks/[tickerID]/financials/cash-flow": [~210,[33,36]],
		"/stocks/[tickerID]/financials/ratios": [~211,[33,36]],
		"/stocks/[tickerID]/forecast": [~212,[33,37]],
		"/stocks/[tickerID]/forecast/ai": [~213,[33,37]],
		"/stocks/[tickerID]/forecast/analyst": [~214,[33,37]],
		"/stocks/[tickerID]/history": [~215,[33]],
		"/stocks/[tickerID]/insider": [~216,[33,38]],
		"/stocks/[tickerID]/insider/congress-trading": [~217,[33,38]],
		"/stocks/[tickerID]/insider/institute": [~218,[33,38]],
		"/stocks/[tickerID]/insider/transcripts": [219,[33,38]],
		"/stocks/[tickerID]/metrics": [220,[33,39]],
		"/stocks/[tickerID]/metrics/[slug]": [~221,[33,39,40]],
		"/stocks/[tickerID]/options": [~222,[33,41]],
		"/stocks/[tickerID]/options/dex": [~223,[33,41,42]],
		"/stocks/[tickerID]/options/dex/expiry": [~224,[33,41,42]],
		"/stocks/[tickerID]/options/dex/strike": [~225,[33,41,42]],
		"/stocks/[tickerID]/options/gex": [~226,[33,41,43]],
		"/stocks/[tickerID]/options/gex/expiry": [~227,[33,41,43]],
		"/stocks/[tickerID]/options/gex/strike": [~228,[33,41,43]],
		"/stocks/[tickerID]/options/hottest-contracts": [~229,[33,41]],
		"/stocks/[tickerID]/options/oi": [~230,[33,41,44]],
		"/stocks/[tickerID]/options/oi/expiry": [~231,[33,41,44]],
		"/stocks/[tickerID]/options/unusual-activity": [~232,[33,41]],
		"/stocks/[tickerID]/options/volatility": [~233,[33,41,45]],
		"/stocks/[tickerID]/profile": [~234,[33,46]],
		"/stocks/[tickerID]/profile/employees": [~235,[33,46,47]],
		"/stocks/[tickerID]/statistics": [~236,[33,48]],
		"/stocks/[tickerID]/statistics/fail-to-deliver": [~237,[33,48,49]],
		"/stocks/[tickerID]/statistics/market-cap": [~238,[33,48,50]],
		"/stocks/[tickerID]/statistics/price-reaction": [~239,[33,48]],
		"/stocks/[tickerID]/statistics/revenue": [~240,[33,48,51]],
		"/terms-of-use": [241],
		"/update-password": [~242],
		"/watchlist/options": [~243,[52]],
		"/watchlist/stocks": [~244,[52]],
		"/workspace": [~245],
		"/workspace/[slug]": [~246]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.svelte";